import React from "react";
import "./Testimonials.css";
import AVTR1 from "../../assets/avatar1.jpg";
import AVTR2 from "../../assets/avatar2.jpg";
import AVTR3 from "../../assets/avatar3.jpg";
import AVTR4 from "../../assets/avatar4.jpg";

// import Swiper core and required modules
import { Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: AVTR1,
    name: "Tina Snow",
    review:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum obcaecati veritatis rem atque nam delectus alias. Aut explicabo, sequi deleniti recusandae fugiat maiores dignissimos, cupiditate odio provident molestiae, magnam numquam sed accusantium quis error.",
  },
  {
    avatar: AVTR2,
    name: "Shatta Wale",
    review:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis iure tempora reprehenderit ipsa ducimus. Corporis doloribus asperiores perspiciatis obcaecati consectetur, error animi voluptate exercitationem rerum quidem minus consequatur at iste, alias est.",
  },

  {
    avatar: AVTR3,
    name: "Kwame Despite",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quibusdam voluptas blanditiis ex, tempore dignissimos soluta perferendis vero veritatis consectetur rem ducimus officia minus. Ipsum distinctio modi est quaerat voluptatibus, ducimus cum ab?",
  },

  {
    avatar: AVTR4,
    name: "Nana Ama McBrown",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati nemo mollitia sunt iure, saepe pariatur architecto inventore cupiditate ut deleniti nihil quidem, nisi ipsa doloremque facilis animi ullam ratione odio dolores optio voluptas.",
  },
];

const Testimonials = () => {
  return (
    <section id="testimoniasl">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container" // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, review, name }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt={name} />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
